import { getFileExtension } from '@/shared/helpers';
import { isObj } from '@/shared/utils';

export const getTreeView = async (parent, treeData = [], lastIndexes = []) => {
	const initIndex = 0;
	if (parent.replyTo === 0) {
		treeData.push({
			id: parseInt(parent.id, 10),
			xid: parent.xid
		});
		lastIndexes.push(initIndex);
	}

	return await getDeepTree(parent, treeData, lastIndexes);
};

export const getDeepTree = async (parent, treeData = [], lastIndexes = []) => {
	const data = await getItemComments(parent.xid, parent.id);
	const currentLastIndexLength = lastIndexes.length;
	if (data.length > 0) {
		let getDeep = {};
		lastIndexes.forEach((lastIndex, i) => {
			if (i === 0) {
				getDeep = treeData[lastIndex];
			} else {
				getDeep = getDeep.children[lastIndex];
			}
		});
		this.$set(getDeep, 'children', []);
		let index = 0;
		for (const node of data) {
			if (lastIndexes.length > currentLastIndexLength) {
				lastIndexes.pop();
			}
			lastIndexes.push(index);
			getDeep.children.push({
				id: parseInt(node.id, 10),
				xid: node.xid,
				detail: node
			});

			//merge
			_.merge(treeData, getDeep);

			treeData = await getTreeView(node, treeData, lastIndexes);
			index++;
		}
	}

	return treeData;
};

/**
 * @param {Array} list
 * @param {String} srcPath
 * @return {Array}
 */
export const generatePreviewList = (list, srcPath) => {
	if (!Array.isArray(list)) {
		throw new Error("First parameter should be an 'Array'");
	}

	return list.map((img) => {
		return isObj(img)
			? img
			: {
					xid: img,
					src: srcPath + img,
					thumb: process.env.VUE_APP_PATH_IMG_THUMB_URL + img,
					ext: getFileExtension(img)
			  };
	});
};
