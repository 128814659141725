<template>
	<div class="adobe-pdf-reader-file--component-wrapper">
		<div class="full full_h clearfix">
			<div v-if="!isShowSignatureViewerWarning" style="height: 100%">
				<div v-if="!adobeApiPDFReady" class="loading-container">
					<b-spinner variant="primary" />
				</div>
				<div v-show="adobeApiPDFReady" :id="containerId" ref="adobePdfReader" />
			</div>
			<div style="height: 100%" v-else>
				<b-overlay :show="loading" opacity="0.85" style="height: 100%">
					<iframe class="full full_h clearfix" :src="src" frameborder="0" @load="onFrameLoaded" />
					<template #overlay>
						<div class="text-center bg-transparent">
							<loading :active.sync="loading" loader="bars" color="#00A09C" :width="64" />
							<p class="loading-text">
								{{ FormMSG(100, 'Please wait...') }}
							</p>
						</div>
					</template>
				</b-overlay>
			</div>
		</div>
	</div>
</template>

<script>
import mapProps from '@/shared/vuePropsMapper';
import { isNil, makeID } from '@/shared/utils';
import { cleanLinkQuery } from '@/shared/helpers';
import { getLangISOByIndex } from '@/helpers/translate';
import { store } from '@/store';
import filePreviewerMixin from '@/components/Packages/FIlesPreviewer/filePreviewer.mixin';
import languageMessages from '@/mixins/languageMessages';
import Loading from 'vue-loading-overlay';

export default {
	name: 'AdobePdfReaderFilePreviewerComponent',
	mixins: [filePreviewerMixin, languageMessages],
	components: {
		Loading
	},
	props: {
		...mapProps(['src', 'xid'], {
			type: String,
			required: true,
			default: null
		}),
		manager: {
			type: Object,
			required: true,
			default: () => {}
		}
	},
	data() {
		return {
			adobeApiPDFReady: false,
			adobeDCView: null,
			containerId: `adobe-dc-view__${makeID(10)}`,
			isSignatureToastVisible: false,
			loading: false
		};
	},
	computed: {
		/**
		 * @return {String}
		 */
		getCurLangStr() {
			return getLangISOByIndex(store.appLanguage()).iso;
		},

		/**
		 * @return {Boolean}
		 */
		isShowSignatureViewerWarning() {
			const currF = this.manager.states.currFileDetails;
			if (isNil(currF)) return false;

			const _sv = currF.showSignatureViewerWarning;
			this.isSignatureToastVisible = _sv;

			if (this.isSignatureToastVisible) {
				this.loading = true;
			}

			return _sv;
		}
	},
	mounted() {
		this.setAdobePreviewer();
	},
	watch: {
		adobeApiPDFReady(val) {
			if (!val) return;
			this.buildPdfViewer(val);
		}
	},
	methods: {
		setAdobePreviewer() {
			if (this.isShowSignatureViewerWarning) return;
			/**
			 * work arround inspired from this thread
			 * https://community.adobe.com/t5/acrobat-services-api-discussions/problem-pdf-embed-api-dc-core-loaded-twice/td-p/13938274
			 */
			if (isNil(window.AdobeDC)) {
				// Dynamically load Adobe SDK Viewer for this page
				const plugin = document.createElement('script');
				plugin.setAttribute('src', 'https://documentcloud.adobe.com/view-sdk/viewer.js');
				plugin.async = true;
				document.head.appendChild(plugin);

				document.addEventListener('adobe_dc_view_sdk.ready', () => (this.adobeApiPDFReady = true));
			} else {
				this.adobeApiPDFReady = true;
			}
			// this.loadViewerScript();
		},

		openPDF() {
			if (isNil(this.adobeDCView)) return;

			this.adobeDCView.previewFile(
				{
					content: { location: { url: cleanLinkQuery(this.src) } },
					metaData: { fileName: this.xid }
				},
				{
					// embedMode: "SIZED_CONTAINER",
					enableFormFilling: true,
					showDownloadPDF: false,
					showLeftHandPanel: false,
					showAnnotationTools: false
				}
			);
		},

		/**
		 * @param {Boolean} status
		 */
		buildPdfViewer(status) {
			if (!status || isNil(window.AdobeDC)) return;

			// val == true ; Adobe is loaded on page
			const previewFilePromise = new window.AdobeDC.View({
				clientId: this.$isPwa() ? process.env.VUE_APP_ADOBE_PDF_API_KEY_LOCAL : process.env.VUE_APP_ADOBE_PDF_API_KEY,
				divId: this.containerId,
				locale: this.getCurLangStr
			});
			this.adobeDCView = Object.freeze(previewFilePromise);

			const saveOptions = {
				autoSaveFrequency: 0,
				enableFocusPolling: false,
				showSaveButton: true
			};

			this.adobeDCView.registerCallback(
				AdobeDC.View.Enum.CallbackType.SAVE_API,
				async (metaData, content, options) => {
					console.log({ type: 'SAVE_API', metaData, content, options });

					const uint8Array = new Uint8Array(content);
					const blob = new Blob([uint8Array], { type: 'application/pdf' });

					await this.updatePdfFile(blob);

					return new Promise((resolve, reject) => {
						resolve({
							code: AdobeDC.View.Enum.ApiResponseCode.SUCCESS,
							data: {
								metaData: { fileName: this.xid }
							}
						});
					});
				},
				saveOptions
			);

			this.openPDF();
		},
		onFrameLoaded() {
			this.loading = false;
		}
	}
};
</script>

<style lang="scss" scoped>
.adobe-pdf-reader-file--component-wrapper {
	position: relative;

	&,
	#adobe-dc-view {
		width: 100%;
		height: 100%;
	}

	.loading-container {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		margin: auto;

		&,
		span.spinner-border {
			width: 100px;
			height: 100px;
		}
	}
}
</style>
